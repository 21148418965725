function close (el) {
  el.style.display = 'none'
}

function open (el) {
  el.style.display = 'block'
  el.style.position = 'absolute'
  el.style.transform = 'translate3d(-178px, 24px, 0px)'
  el.style.top = '0px'
  el.style.left = "0px"
}

export default (el, binding) => {
  // console.log('load dropdown directive')
  // console.log(binding)
  const dropDownMenu = el.getElementsByClassName(binding.value).length ? el.getElementsByClassName(binding.value)[0] : false
  dropDownMenu.style.display = 'none'
  window.addEventListener('click', (e) => {
    if (dropDownMenu) {
      if (el.contains(e.target)) {
        // console.log('inside')
        open(dropDownMenu)
      } else {
        // console.log('outside')
        close(dropDownMenu)
      }
      // dropDownMenu.style.position = 'absolute'
      // dropDownMenu.style.transform = 'translate3d(-178px, 24px, 0px)'
      // dropDownMenu.style.top = '0px'
      // dropDownMenu.style.left = "0px"
      // dropDownMenu.style.willChange = 'transform'
      // console.log(dropDownMenu)
    }
  })
}
